import React from "react";

// eslint-disable-next-line
import universal from '../assets/styles/Universal.module.scss';

class Footer extends React.Component {
  render() {
    return <footer>Copyright©2019 Il Varco</footer>;
  }
}

export default Footer;
